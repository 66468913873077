<template>
	<div>
		<div class="flex mb-4 relative">
			<b-button class="w-1/12" @click="openMemo" variant="gray">메모 </b-button>
			<ul class="ml-8 flex absolute" style="list-style: disc; top: 0.6em; right: 2em">
				<li class="mr-12">등록일: {{ regDt }}</li>
				<li>업데이트일: {{ updateDt }}</li>
			</ul>
		</div>
		<div v-for="v in ['계약 정보', '기본 정보', '담당자 정보', '정산 정보']" class="collapseWrap">
			<div class="collapseHeader disabled">
				{{ v }}
				<b-icon class="absolute top-3 right-3" icon="caret-down-fill" variant="gray" />
			</div>
		</div>

		<!--패키지, 광고한도액 정보-->
		<collapse initShow title="패키지, 광고한도액 정보">
			<vtb :key="advertiseInfo[1].cont" :data="advertiseInfo" oneColumn titleSize="3">
				<template #managementUnit>
					<div class="flex">
						<b-form-radio
							v-for="(v, i) in ['파트너사', '상품그룹']"
							:key="v + i"
							class="mr-3 align-middle"
							v-model="advertiseManagementUnit"
							name="advertiseManagementUnit"
							@change="$set(editField, 'advertiseManagementUnit', v)"
							:value="v"
						>
							{{ v }}
						</b-form-radio>
					</div>
				</template>
				<template #adLimitAmt>
					<b-form-input
						:class="adLimitAmtError ? 'error' : ''"
						class="w-80"
						v-model.trim="adLimitAmt"
						placeholder="광고한도액 입력"
						@blur="inputFocusOut()"
						@change="$set(editField, 'adLimitAmt', adLimitAmt)"
						@focusin="inputFocusIn()"
						:disabled="isProductGroup"
				/></template>
			</vtb>

			<div class="flex mt-5">
				<v-select class="w-1/6" v-model="changeAllPackageFunc" :clearable="false" :options="packageOpts" />
				<b-form-input
					class="w-1/6 ml-4"
					v-model.trim="changeAllAmt"
					placeholder="프리미엄·기본 패키지 광고한도액 입력"
					@blur="inputFocusOut(undefined, 'changeAllAmt')"
					@focusin="inputFocusIn(undefined, 'changeAllAmt')"
					:disabled="!isEditableChangeAllAmt || !isProductGroup"
				/>
				<b-button class="ml-4" @click="changeAll" variant="info">판매상품 일괄 적용</b-button>
			</div>

			<tb class="mt-4" :inf="productGroup.inf" :pr="ths" :res="productGroup.res" limit="30" />
			<p v-show="isEditedPage" class="absolute right-24 bottom-4 text-danger">
				페이지를 이동하면 현재 페이지에서 수정하던 내용이 사라집니다.<br />저장 버튼을 눌러 저장하신 후 다른
				페이지로 이동하세요.
			</p>
		</collapse>
		<div class="text-center">
			<b-button
				v-for="v in [
					{ name: '저장', color: 'info', func: save },
					{ name: '취소', color: 'gray', func: cancel },
				]"
				class="mx-1 px-16"
				@click="v.func"
				:variant="v.color"
				v-text="v.name"
			/>
		</div>
	</div>
</template>

<script>
import collapse from 'comp/collapse'
import partnerMixIn from 'pages/partnerMixIn.js'

export default {
	components: { collapse },
	mixins: [partnerMixIn],
	data() {
		const packageOpts = [
			{ label: '패키지 정보 선택', value: 'no' },
			{ label: '테스트 패키지', value: '1' },
			{ label: '프리미엄 패키지', value: '2' },
			{ label: '베이직 패키지', value: '3' },
			{ label: '기본 패키지', value: '4' },
		]

		return {
			adLimitAmtError: false,
			adLimitAmt: 0,
			advertiseManagementUnit: '',
			packageOpts,
			packageOptsInTable: [...packageOpts].splice(1),
			changeAllPackage: packageOpts[0],
			isEditableChangeAllAmt: true,
			changeAllAmt: '',
			editField: { productGroup: {} },
			//advertiseManagementUnit: -, adLimitAmt: -, productGroup: { seq: {} }
			//이따가 페이지 이동해도 수정 내역 남고 저장되도록 해달라고 해서 미리 준비
		}
	},
	created() {
		const inf = this.productGroup.inf

		//판매여부
		inf[5] = { ...inf[5], model: 'checkBox', func: this.changeProductGroup }

		//패키지 정보
		inf[6] = {
			...inf[6],
			model: 'dropdown',
			options: this.packageOpts,
			func: this.changePackage,
			disabled: v => !v.isSales,
		}

		//광고한도액
		inf[7] = {
			...inf[7],
			size: 12,
			model: 'input',
			placeholder: '광고한도액 입력',
			func: this.changeProductGroup,
			focusIn: this.inputFocusIn,
			focusOut: this.inputFocusOut,
			addClass: v => (v.isError ? 'error' : ''),
			disabled: v => !v.isSales || !this.isProductGroup || !this.isEditablePackageAmt(v)[0],
		}

		this.advertiseInfo[0] = { ...this.advertiseInfo[0], model: 'managementUnit' }
		this.advertiseInfo[1] = {
			...this.advertiseInfo[1],
			model: 'adLimitAmt',
		}

		postApi('/partnerSelect/partnerDetail', { storeSeq: this.storeSeq }).then(res => {
			let data = res.advertiseInfo
			this.isPartner = data.advertiseManagementUnit == '파트너사'
			this.adLimitAmt = data.adLimitAmt
			this.advertiseManagementUnit = data.advertiseManagementUnit

			layout.pageTitle = layout.$route.meta.pageTitle + ' - ' + res.companyName
			this.storeName = res.companyName
			if (res.regDt) this.regDt = res.regDt.replace('T', ' ')
			if (res.updateDt) this.updateDt = res.updateDt.replace('T', ' ')
			this.changePage()
		})
	},
	methods: {
		inputFocusIn(v, key = 'adLimitAmt') {
			const target = v ? v : this
			this.$set(target, key, target[key].toString().replace(/,/g, ''))
		},
		inputFocusOut(v, key = 'adLimitAmt') {
			const target = v ? v : this
			this.$set(target, key, comma(target[key]))
		},
		getPackageName(p) {
			if (typeof p != 'object') return p
			if (p.packageName) return p.packageName.label ? p.packageName.label : p.packageName
			return p.label ? p.label : p.packageName
		},
		getPackageCode(v) {
			if (typeof v == 'object' && v != null) v = v.label
			switch (v) {
				case '테스트 패키지':
					return 1
				case '프리미엄 패키지':
					return 2
				case '베이직 패키지':
					return 3
				case '기본 패키지':
					return 4
			}
			return null
		},
		isEditablePackageAmt(v) {
			if (typeof v != 'string') v = this.getPackageName(v)
			switch (v) {
				case '베이직 패키지':
					return [false, 0]
				case '테스트 패키지':
					return [false, '33,000']
				default:
					//프리미엄 패키지, 변경 안 함
					return [true, -1]
				//this.pData.advertiseManagementUnit == '상품그룹' 상품 그룹 조건은 계속 바뀌는 값이라 여기서 넣으면 안 되고 태그에 넣어야 함
			}
			return [false, -1]
		},
		changePage(page = 1) {
			this.changePageAction(page).then(res => {
				this.productGroup.res = res
				this.editField = { productGroup: {} }
				return res
			})
		},
		changeAll() {
			//이건 그냥 두 번으로 나눠서 해야겠다... 기획 변경으로 광고한도액 쪽이 너무 복잡해짐
			if (this.changeAllPackage.value != 'no') {
				this.productGroup.res.list.map(p => {
					if (p.isSales) {
						p.packageName = this.changeAllPackage.label
						this.changeProductGroup(p)
					}
				})
			}

			if (this.changeAllAmt !== '') {
				const isPremiumOnly =
					this.changeAllPackage.value == 'no' || this.isEditablePackageAmt(this.changeAllPackage)[0]
				this.productGroup.res.list.map(p => {
					if (
						p.isSales &&
						(!isPremiumOnly || (this.isProductGroup && this.isEditablePackageAmt(p.packageName)[0]))
					) {
						//premiumOnly일 때는 관리단위도 상품그룹으로 맞춰져 있어야 함
						p.adLimitAmt = this.changeAllAmt
						this.changeProductGroup(p)
					}
				})
			}
		},
		changeProductGroup(p) {
			this.$set(this.editField.productGroup, p.productGroupIdx, p)
		},
		changePackage(p) {
			const packageName = this.getPackageName(p)
			if (packageName == '베이직 패키지') p.adLimitAmt = 0
			else if (packageName == '테스트 패키지') p.adLimitAmt = '33,000'
			this.changeProductGroup(p)
		},
		cancel() {
			if (this.isEditedPage)
				confirm('수정하시던 내용이 삭제됩니다.\r\n수정을 취소하시겠습니까?', () => this.$router.go(-1))
			else this.$router.go(-1)
		},
		save() {
			this.adLimitAmtError = false
			let isExistPremium = false,
				isExistDefault = false
			this.productGroup.res.list.map(v => {
				if (v.isSales) {
					switch (this.getPackageCode(v.packageName)) {
						case 2:
							isExistPremium = true
							break
						case 4:
							isExistDefault = true
					}
				}
			})

			const adLimitAmt = this.adLimitAmt !== null ? this.adLimitAmt.toString().replace(/,/g, '') : 0
			let error = []
			if (this.advertiseManagementUnit == null || this.advertiseManagementUnit == '')
				error.push('광고한도액 관리 단위를 선택해 주세요.')
			//현재 페이지 상품 중에 프리미엄 패키지가 있고, 광고한도액 관리 단위가 선택 안 되어 있는 경우 오류 추가

			if (!this.isProductGroup) {
				if (adLimitAmt === '' || isNaN(adLimitAmt)) {
					this.adLimitAmtError = true
					error.push('파트너사의 광고한도액이 입력되지 않았습니다.')
				}
			} else {
				this.productGroup.res.list.map(v => {
					if (
						v.isSales &&
						(v.adLimitAmt === null ||
							v.adLimitAmt === '' ||
							isNaN(v.adLimitAmt.toString().replace(/,/g, '')))
					) {
						v.isError = true
						error.push('상품의 광고한도액이 입력되지 않았습니다.')
					}
				})
			}

			if (isExistPremium && isExistDefault)
				error.push(
					'판매여부 Y 상품 중에 프리미엄 패키지와 기본 패키지가 함께 존재합니다.\r\n둘 중 한 패키지만 선택하세요.'
				)

			if (error.length) alert.w(error)
			else {
				let changeKey = Object.keys(this.editField)
				const changeProduct = Object.keys(this.editField.productGroup)
				changeKey.splice(changeKey.indexOf('productGroup'), 1)

				if (changeKey.length + changeProduct.length) {
					const isProductGroup = this.isProductGroup
					let data = {
						storeSeq: this.storeSeq,
						adminSeq: layout.user.seqNo,
					}
					changeKey.forEach(k => {
						data[k] = this.editField[k]
					})
					if (data.advertiseManagementUnit)
						data.advertiseManagementUnit = data.advertiseManagementUnit == '파트너사' ? 1 : 2

					if (!isProductGroup && !data.adLimitAmt)
						data.adLimitAmt = this.adLimitAmt.toString().replace(/,/g, '')
					//파트너사일 때 무조건 보내도록 수정..

					if (changeProduct.length) {
						data.updateProductGroups = []
						changeProduct.forEach(k => {
							const v = this.editField.productGroup[k]
							let pgData = {
								productGroupIdx: k * 1,
								isSales: v.isSales,
								//판매중이 아니라면 판매 여부만 업데이트하기로 함
							}
							if (v.isSales) {
								pgData.packageCd = this.getPackageCode(v.packageName)
								if (!pgData.packageCd) pgData.packageCd = null
								if (isProductGroup || this.isEditablePackageAmt(v.packageName)[0])
									pgData.adLimitAmt = v.adLimitAmt.toString().replace(/,/g, '') * 1
								//관리 단위가 파트너사라면 프리미엄 패키지의 광고한도액은 변경사항이 있어도 업데이트하지 않음
							}
							data.updateProductGroups.push(pgData)
						})
					}

					putApi('/partnerHandle/updatePartner', data).then(v => {
						alert.s('수정이 완료되었습니다')
						this.$router.go(-1)
						//this.editField = {}
					})
				} else alert.w('수정된 내용이 없습니다.')
			}
		},
	},
	computed: {
		isProductGroup() {
			return this.advertiseManagementUnit == '상품그룹'
		},
		isEditedPage() {
			return Object.keys(this.editField).length > 1 || Object.keys(this.editField.productGroup).length
		},
		changeAllPackageFunc: {
			get() {
				return this.changeAllPackage
			},
			set(v) {
				this.changeAllPackage = v
				const [isEditableChangeAllAmt, changeAllAmt] = this.isEditablePackageAmt(v.label)
				this.isEditableChangeAllAmt = isEditableChangeAllAmt
				if (!isEditableChangeAllAmt) this.changeAllAmt = changeAllAmt
			},
		},
	},
}
</script>
